<template>
  <v-dialog
    class="DialogLoading"
    v-model="showDialog"
    persistent
    width="350"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text><br>
        <p class="subtitle-1">{{text ? text: 'Espera un momento, estamos obteniendo tu información'}}
        </p>
        <v-progress-linear
          indeterminate
          color="accent"
          class="mb-3 mt-3"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogLoading",
  props: {
    value: Boolean,
    text: String,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    value(a) {
      this.showDialog = a;
    },
    showDialog(a) {
      if (!a) {
        this.$emit("input", a);
      }
    },
  },
  created() {
    this.showDialog = this.value;
  },
};
</script>