<template>
  <div class="CitasForm">
    <v-card
      class="mt24"
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="'Programa tu cita'" />
      <v-stepper
        v-model="posicion"
        flat
        class="elevation-0"
      >
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :complete="posicion > 1"
            step="1"
          >
            Fecha
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="posicion > 2"
            step="2"
          >
            Horario
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3"> Confirmación </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="subtitle-1 mb24">Escoge el día de tu preferencia</div>
            <v-list flat>
              <v-list-item-group
                v-model="serviciosActivos"
                multiple
              >
                <div
                  v-for="date in _calculateBusinessDays()"
                  :key="date"
                >
                  <v-list-item @click="_getHorarios(date)">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="_getReadableDate(date)" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon><v-icon>chevron_right</v-icon></v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider />
                </div>
              </v-list-item-group>
            </v-list>
            <ButtonStepperActions
              showPrevious
              previousText="Cancelar"
              @previous="$router.push('/')"
              @next="
                _getHorarios();
              posicion++;
              "
            />
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="subtitle-1 mb24">Ahora, escoge el horario de tu preferencia</div>
            <v-list flat>
              <v-list-item-group
                v-model="serviciosActivos"
                multiple
              >
                <div
                  v-for="horario in citas"
                  :key="horario"
                >
                  <v-list-item @click="cita.hora_cit=horario;posicion++">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="_getReadableHorario(horario)" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon><v-icon>chevron_right</v-icon></v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider />
                </div>
              </v-list-item-group>
            </v-list>
            <ButtonStepperActions
              showPrevious
              @previous="posicion--"
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-row>
              <v-col
                cols="12"
                offset="0"
                sm="6"
                offset-sm="3"
                md="4"
                offset-md="4"
              >
                <!-- <v-img
                  src="@/assets/avatars/citas/cita-confirmacion.png"
                  style="max-width:400px;width:100%;height:auto"
                  class="mb24"
                /> -->

                <v-img
                  src="@/assets/dr.jpg"
                  style="max-width:400px;width:100%;height:auto"
                  class="mb24"
                />
                <div class="title mt24 text-center">
                  {{ $session.get("nombre") }}, tu cita se programará para el <strong>{{
                    $Datetime
                      .fromSQL(cita.fecha_cit+' '+cita.hora_cit)
                      .setLocale("es-Mx")
                      .toFormat("DDDD 'a las' h:mm a")
                  }}
                  </strong>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <p class="text-center">Te recomendamos llegar por lo menos 10 minutos antes de tu cita</p>
              </v-col>
            </v-row>
            <v-card-action>
              <v-btn block class="primary" @click="_guardarCita">!Confirmar y guardar!</v-btn>
            </v-card-action>
            <br>
            <ButtonStepperActions
              showPrevious
              @previous="posicion--"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <dialog-loading
      v-model="dialogCargando"
      text="Espera un momento, estamos guardando tu cita."
    />
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import ButtonStepperActions from "@/components/forms/buttons/ButtonStepperActions.vue";
import DialogLoading from "@/components/dialogs/DialogLoading.vue";
import { Interval, DateTime } from "luxon";
export default {
  name: "CitasForm",
  mixins: [standard, forms],
  components: { ButtonStepperActions, DialogLoading },
  data() {
    return {
      posicion: 1,
      servicios: [],
      serviciosActivos: [],
      serviciosId: [],
      horarios: [],
      cita: {
        fecha_cit: null,
        servicios: [],
      },
      //cosas que se despliegan
      citasListadas: [],
      fechasListadas: [],
      //data obtenida del get
      citas: [],
      medicos: [],
      fechas: [],
      medicoSeleccionado: null,
      fechaSeleccionada: {},
      dialogCargando: false,
    };
  },
  methods: {
    _calculateBusinessDays() {
      const startDate = DateTime.local();
      const endDate = startDate.plus({ week: 2 });
      return Interval
        .fromDateTimes(
          startDate.startOf("day"),
          endDate.endOf("day")
        )
        .splitBy({ day: 1 })
        .map((date) => date.start.toISODate())
        .filter((date)=> DateTime.fromISO(date).weekday != 7);

    },
    _getReadableDate(date) { 
      return DateTime.fromSQL(date).setLocale('es-Mx').toFormat("dd 'de' LLL")
    },
    _getReadableHorario(hour){
      return DateTime.fromSQL(this.cita.fecha_cit+' '+hour).setLocale('es-Mx').toFormat("h':'mm a")
    },
    async _getHorarios(fecha) {
      const errorMsg =
        "Sucedió un error al obtener las citas disponibles. Inténtalo de nuevas tarde.";
      this.cargando = true;
      this.cita.fecha_cit = fecha;
      try {
        const params = this._arrToGetParams({ fecha: fecha });
        const { status, body } = await this.$http.get(
          "horarios/fecha" + params,
          this.$headers
        );
        if (status === 200 && "horarios" in body) {
          this.citas = body.horarios;
          this.posicion = 2;
        } else {
          this.$emit("msg", errorMsg);
        }
        this.cargando = false;
      } catch (err) {
        this.cargando = false;
        this.$emit("msg", errorMsg);
      }
    },
    async _guardarCita() {
      const txtError =
        "Sucedió un error al guardar tu cita. Inténtalo de nuevo más tarde";
      const data = {
        cita:{fecha: this.cita.fecha_cit + ' '+this.cita.hora_cit},
        servicios: [],
      };
      this.cargando = true;
      this.dialogCargando = true;
      try {
        const { status, body } = await this.$http.post(
          "citas",
          data,
          this.$headers
        );
        if (status === 201) {
          this._handleCitaGuardada(body);
        } else {
          this.$emit("msg", txtError);
        }
        this.dialogCargando = false;
        this.cargando = false;
      } catch (err) {
        this.$emit("msg", txtError);
        this.dialogCargando = false;
        this.cargando = false;
      }
    },
    _handleCitaGuardada(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/confirmaciondecita/" + res.id);
    },

  },
  created() {
  },
};
</script>
